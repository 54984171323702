<template>
  <div class="about h-full">

  <h1 class="text-2xl mx-4">About Us</h1>
  <div class="text">
    <div class="w-2/3 text-xl leading-relaxed pt-8">
    <p>
      Clarity Title, LLC is a full-service title and real estate settlement provider that operates in the State of Florida. Our clients are our first priority. Our goal is to make the closing process smooth and as easy as possible for all parties involved.  We are passionate about what we do, dedicated to the industry and are committed to giving exceptional service.  Clarity Title, LLC delivers superior service at the most competitive rate in the industry.
    </p>
    <br>
    <p>
      Homebuyers and Sellers are in great hands when they choose Clarity Title, LLC to be their trusted real estate closing and escrow experts. We are experienced, knowledgeable and dedicated to you and your transaction in every way.  We know that the closing process can get complicated at times, but we are here to guide you to a smooth and successful closing. Whether this is your first real estate transaction or your 100th, you deserve an expert title company you can trust.
    </p>
    <br>
    <p>
      In addition to a strong team of title professionals, we are backed by one of the strongest underwriters in the nation.
    </p>
  </div>
  </div>

  <h1 class="text-2xl mx-4 mt-2">About Ana</h1>
  <div class="text">
    <div class="w-2/3 text-xl leading-relaxed pt-6">
    <p>
      Ana began her career in real estate in 1999 while working as a Certified Paralegal for an Orlando law firm. She then moved on to manage a title company in Winter Springs.  After skillfully managing that title company for over 12 years, Ana decided to open her own company.  She works well under pressure and has extensive experience in all aspects of escrow, including residential sales, short sales, commercial sales, refinances and 1031 exchanges.
    </p>
    <br>
    <p>
      Ana understands the importance of communication and time management.  She enjoys all aspects of the industry, particularly working with people.  Her dedication to her clients and understanding of each aspect of the transaction gives her the experience to handle those challenges that arise during a real estate transaction to ensure a successful closing.
    </p>
    <br>
    <p>
      Ana holds the license of Title Agent and Notary with the State of Florida, and Certified Paralegal with the National Association of Legal Assistants.
    </p>
    <br>
    <p>
      She looks forward to handling your next closing and encourages you to call with any questions you may have before, during and after the transaction.
    </p>
  </div>
  </div>

  <div class="text">
    <ul class="w-2/3 text-xl leading-relaxed pt-4 pb-8">
      <li class="font-bold">Ana R. Groe, CP</li>
      <li>Clarity Title, LLC</li>
      <li>Owner/Title Agent</li>
      <li>Phone: <a class="underline" href="tel:4073509523">407-350-9523</a></li>
      <li>Email: <a class="underline" href="mailto:ana@claritytitlefl.com">ana@claritytitlefl.com</a></li>
    </ul>
  </div>
  </div>

</template>

<script>

export default {

}
</script>

<style scoped>
.text {
display: flex;
justify-content: center;
}
</style>
